const contractAddresses = {
  masterChef: "0x6dDdfE9965aEd0Eaa7B28F05940D15D1c688307f",
  multiCall: "0x86FA85a252C8b2955b80bF9A7764cC65587f4D0e",
  zap: "0x9D50421dfDAdC7d10278b3D0a24edc61480dc40d",
  router: "0xCa4EAa32E7081b0c4Ba47e2bDF9B7163907Fe56f",
  factory: "0x591f122D1df761E616c13d265006fcbf4c6d6551",
  gem: "0x0D3f4e01E7d782eC321E3022B5E8F11709D74180",
  weth: "0x4200000000000000000000000000000000000006",
  usdc: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
  nft: "0xCD2aA3A27fa0eDF880391C3846BE438e2A1E0B49",
  usdcLp: "0x6bee1580471F38000951abd788A9C060A4ad3Ac3",
  gemWethlp: "0x8f6cd16aCAD640F4a5f29310eeB6D7CC79e3A47A",
  presaleContract: "0x5BF0379bD6fB1B7Ccb4a6b85f6C775521064f1E2",
  presaleFork: "0x5c5BC3C1b4132cbED8f2EEe31a12f42Cf9c612B8"
};

export default contractAddresses;