import tokens from "./tokens";
import addresses from "constants/addresses";

export const liquidityList = [
  {
    pid: 0,
    lpSymbol: tokens.gem.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.gem.address,
    decimals: 18,
    logoA: tokens.gem.logo,
    logoB: "",
  },
  {
    pid: 0,
    lpSymbol: tokens.eth.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.eth.address,
    decimals: 18,
    logoA: tokens.eth.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.weth.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.weth.address,
    decimals: 18,
    logoA: tokens.weth.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: "WETH-GEM",
    isTokenOnly: false,
    lpAddresses: addresses.gemWethlp,
    decimals: 18,
    logoA: tokens.gem.logo,
    logoB: tokens.weth.logo,
  },
];

export const zapList = [
  {
    pid: 0,
    lpSymbol: tokens.gem.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.gem.address,
    decimals: 18,
    logoA: tokens.gem.logo,
    logoB: "",
  },
  {
    pid: 0,
    lpSymbol: tokens.eth.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.eth.address,
    decimals: 18,
    logoA: tokens.eth.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.weth.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.weth.address,
    decimals: 18,
    logoA: tokens.weth.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: "WETH-GEM",
    isTokenOnly: false,
    lpAddresses: addresses.gemWethlp,
    decimals: 18,
    logoA: tokens.gem.logo,
    logoB: tokens.weth.logo,
  },
];

const farmsConfig = [
  {
    pid: 0,
    lpSymbol: "WETH-GEM",
    lpAddresses: addresses.gemWethlp,
    isTokenOnly: false,
    isNFTPool: false,
    token: tokens.gem,
    quoteToken: tokens.weth,
    logoA: tokens.gem.logo,
    logoB: tokens.weth.logo,
  },
  {
    pid: 1,
    lpSymbol: "WETH-USDC",
    lpAddresses: addresses.usdcLp,
    isTokenOnly: false,
    isNFTPool: false,
    token: tokens.usdc,
    quoteToken: tokens.weth,
    logoA: tokens.weth.logo,
    logoB: tokens.usdc.logo,
  },
  {
    pid: 2,
    lpSymbol: "GEM",
    lpAddresses: addresses.gem,
    isTokenOnly: true,
    isNFTPool: false,
    token: tokens.gem,
    quoteToken: tokens.gem,
    logoA: tokens.gem.logo,
    logoB: tokens.gem.logo,
  },
  // {
  //   pid: 3,
  //   lpSymbol: "WETH",
  //   lpAddresses: addresses.weth,
  //   isTokenOnly: true,
  //   isNFTPool: false,
  //   token: tokens.weth,
  //   quoteToken: tokens.weth,
  //   logoA: tokens.weth.logo,
  //   logoB: tokens.weth.logo,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: "GEMNFT",
  //   lpAddresses: addresses.nft,
  //   isTokenOnly: true,
  //   isNFTPool: true,
  //   token: tokens.nft,
  //   quoteToken: tokens.gem,
  //   logoA: "/assets/tokens/nft.png",
  //   logoB: "",
  // },
];

export default farmsConfig;
