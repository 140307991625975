import { CircleBackground } from "components/CircleBackground";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";

export default function index({ children }) {
  return (
    <div>
      <Header />
      <div className="flex w-full justify-center items-center  min-h-[calc(100vh-90px)] pb-[100px] relative px-1">
        {children}
        <Footer />
      </div>
    </div>
  );
}
